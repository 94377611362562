<template>
  <div class="content-wrapper__layout">
    <side-nav></side-nav>
    <div class="content-wrapper__main-content-wrapper content-wrapper__main-content-wrapper--31">
      <section class="main-content">
        <router-link v-if="!loading && $store.state.user.id" :to="{ name: 'NewEvent' }" class="btn btn--primary btn--fixed"><img class="icon"
        src="@/assets/images/icons/ico_add--wh.svg"> Crear evento</router-link>
        <section class="section">
          <div class="mobile-only" v-if="isMobile">
            <div class="tabs tabs--sm">
              <div :class="['tab', eventTab == 'all-events' ? 'tab--active tab--gray':'']" @click.prevent="eventTab = 'all-events'">Eventos</div>
              <div :class="['tab', eventTab == 'my-events' ? 'tab--active tab--gray':'']" @click.prevent="eventTab = 'my-events'">Mis eventos</div>
            </div>
            <div v-if="eventTab == 'all-events'">
              <div class="section__titles">
                <h3 class="section__title">Próximos eventos</h3>
              </div>
              <div class="tabs" v-if="$store.state.user.id">
                <div :class="this.tabs == 'prox' ? 'tab tab--active' : 'tab'" @click="this.changePestaña('prox')">
                  Próximos</div>
                <div :class="this.tabs == 'assist' ? 'tab tab--active' : 'tab'" @click="this.changePestaña('assist')">
                  Asistiré</div>
                <div :class="this.tabs == 'interest' ? 'tab tab--active' : 'tab'"
                  @click="this.changePestaña('interest')">
                  Me interesa</div>
              </div>
              <div>
                <!--Listado proximos eventos-->
                <template v-if="!loading && tabs == 'prox'">
                  <EventsListFilters @cancelled="cancelMyEvent" @refresh="RefreshMyEvents"
                    @refreshMonthYears="refreshFilter" :months="months" :monthsNumber="monthsNumber" :years="years"
                    :areas="areas" :causes="causes"></EventsListFilters>
                </template>
                <!--Listado de eventos que asistiré-->
                <template v-if="!loading && tabs == 'assist'">
                  <EventsList @cancelled="cancelMyEvent" @refresh="RefreshMyEvents" @total="totalEventsAttended"
                    @fetchNewEvents="fetchEventsListAssist" :NewEvents="MyEventsListAssist" :months="months"
                    :years="years" :areas="areas" :causes="causes" :Total="total"></EventsList>
                </template>
                <!--Listado de eventos que me interesan-->
                <template v-if="!loading && tabs == 'interest'">
                  <EventsList @cancelled="cancelMyEvent" @refresh="RefreshMyEvents" @total="totalEventsInterest"
                    @fetchNewEvents="fetchEventsListInterest" :NewEvents="EventsListInterest" :months="months"
                    :years="years" :areas="areas" :causes="causes" :Total="total"></EventsList>
                </template>
              </div>
            </div>
            <div v-if="eventTab == 'my-events'">
              <MyEventsList v-if="!loading && $store.state.user.id" @refresh="refreshMyEvents = false"
                @total="totalMyCreatedEvents" @fetchNewEvents="fetchMyEventsList" :EventIdCancel="MyEventIdCancelled"
                :IsRefresh="refreshMyEvents" :NewEvents="MyEventsListCreated" :Total="totalMyCreatedEvents"
                :months="months" :years="years" :areas="areas" :causes="causes"></MyEventsList>
            </div>
          </div>
          <div class="desktop-only" v-else>
            <div class="section__titles">
              <h3 class="section__title">Próximos eventos</h3>
            </div>
            <div class="tabs" v-if="$store.state.user.id">
              <div :class="this.tabs == 'prox' ? 'tab tab--active' : 'tab'" @click="this.changePestaña('prox')">
                Próximos</div>
              <div :class="this.tabs == 'assist' ? 'tab tab--active' : 'tab'" @click="this.changePestaña('assist')">
                Asistiré</div>
              <div :class="this.tabs == 'interest' ? 'tab tab--active' : 'tab'"
                @click="this.changePestaña('interest')">
                Me interesa</div>
            </div>
            <div>
              <!--Listado proximos eventos-->
              <template v-if="!loading && tabs == 'prox'">
                <EventsListFilters @cancelled="cancelMyEvent" @refresh="RefreshMyEvents"
                  @refreshMonthYears="refreshFilter" :months="months" :monthsNumber="monthsNumber" :years="years"
                  :areas="areas" :causes="causes"></EventsListFilters>
              </template>
              <!--Listado de eventos que asistiré-->
              <template v-if="!loading && tabs == 'assist'">
                <EventsList @cancelled="cancelMyEvent" @refresh="RefreshMyEvents" @total="totalEventsAttended"
                  @fetchNewEvents="fetchEventsListAssist" :NewEvents="MyEventsListAssist" :months="months"
                  :years="years" :areas="areas" :causes="causes" :Total="total"></EventsList>
              </template>
              <!--Listado de eventos que me interesan-->
              <template v-if="!loading && tabs == 'interest'">
                <EventsList @cancelled="cancelMyEvent" @refresh="RefreshMyEvents" @total="totalEventsInterest"
                  @fetchNewEvents="fetchEventsListInterest" :NewEvents="EventsListInterest" :months="months"
                  :years="years" :areas="areas" :causes="causes" :Total="total"></EventsList>
              </template>
            </div>
          </div>
        </section>
      </section>
      <!--MIS EVENTOS-->
      <div class="desktop-only">
        <section class="main-content main-content--no-bottom">
          <MyEventsList v-if="!loading && $store.state.user.id" @refresh="refreshMyEvents = false"
        @total="totalMyCreatedEvents" @fetchNewEvents="fetchMyEventsList" :EventIdCancel="MyEventIdCancelled"
        :IsRefresh="refreshMyEvents" :NewEvents="MyEventsListCreated" :Total="totalMyCreatedEvents" :months="months"
        :years="years" :areas="areas" :causes="causes"></MyEventsList>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from '@/components/SideNav.vue'
import EventsList from '@/components/EventsList.vue'
import EventsListFilters from '@/components/EventsListFilters.vue'
import MyEventsList from '@/components/MyEventsList.vue'

export default {
  name: 'EventsView',
  components: { SideNav, EventsList, EventsListFilters, MyEventsList },
  data() {
    return {
      loading: true,
      total: 0,
      totalMyCreatedEvents: 0,
      months: [],
      monthsNumber: [],
      areas: {},
      causes: {},
      EventsListInterest: [],
      MyEventsListAssist: [],
      MyEventsListCreated: [],
      years: [],
      tabs: 'prox',
      filtered: false,
      refreshMyEvents: false,
      MyEventIdCancelled: null,
      eventTab: 'all-events',
      isMobile: false
    }
  },
  beforeUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  },
  async mounted() {
    var list = []
    this.areas = (await this.$axios.get(`areas`)).data
    this.causes = (await this.$axios.get('causas')).data
    this.years = (await this.$axios.get(`Event/listyears`)).data
    list = (await this.$axios.get(`Event/listmonths`)).data
    this.monthsNumber = list
    list.map((item) => {
      this.months.push(this.$moment().set('month', item - 1).format('MMMM'))
    })
    await this.totalMyEvents()

    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
    
    this.loading = false
  },
  methods: {
    onResize () {
      if(window.innerWidth <= 768) {
        return this.isMobile = true
      } else {
        return this.isMobile = false
      }
    },
    getDateObjectFromDateString(dateString){
      const dateParts = (dateString || '').split('-').map(part => parseInt(part))
      if (dateParts.length) {
        dateParts[1]--
      }
      return new Date(...dateParts).toISOString()
    },
    async totalEventsAttended() {
      this.total = (await this.$axios.get(`Event/Totaleventattended`)).data
    },
    async totalEventsInterest() {
      this.total = (await this.$axios.get(`Event/TotalEventsInterest`)).data
    },
    async totalMyEvents() {
      this.totalMyCreatedEvents = (await this.$axios.get(`Event/TotalMyEvents`)).data
    },
    async changePestaña(type) {
      if (type == 'assist') {
        //this.total = await this.totalEventsAttended()
        await this.totalMyEvents()
        await this.RefreshMyEvents()

      } else if (type == 'interest') {
        //this.total = await this.totalEventsInterest()
        await this.totalMyEvents()
        await this.RefreshMyEvents()

      } else if (type == 'prox') {
        await this.totalMyEvents()
        await this.RefreshMyEvents()

      }
      this.tabs = type
    },
    async fetchEventsListAssist(ev) {
      this.MyEventsListAssist = (await this.$axios.get(`Event/EventsAttended/${ev.page}/${true}`)).data
    },
    async fetchEventsListInterest(ev) {
      this.EventsListInterest = (await this.$axios.get(`Event/EventsAttended/${ev.page}/${false}`)).data
    },
    async fetchMyEventsList(ev) {
      this.MyEventsListCreated = (await this.$axios.get(`Event/myevents/${ev.page}`)).data
    },
    async cancelMyEvent(ev) {
      this.MyEventIdCancelled = ev.eventid
    },
    async RefreshMyEvents() {
      const page = 1
      this.refreshMyEvents = true
      await this.totalMyEvents()
      this.MyEventsListCreated = (await this.$axios.get(`Event/myevents/${page}`)).data
    },
    async refreshFilter(extra) {
      this.months.length = 0
      this.monthsNumber.length = 0
      this.years.length = 0
      this.years = (await this.$axios.get(`Event/listyears`)).data

      var list = (await this.$axios.get(`Event/listmonths`)).data

      //Listado de meses tipo numero
      this.monthsNumber = list

      //Listado de nombres de meses
      list.map((item) => {
        this.months.push(this.$moment(this.getDateObjectFromDateString(`2022-${item}-01`)).format('MMMM'))
      })
      var existAño = this.years.filter((item) => item == extra.ano)
      var existMes = this.monthsNumber.filter((item) => item == extra.mes)

      if (existAño.length == 0) {
        extra.ano = 0
      }
      if (existMes.length == 0) {
        extra.mes = 0
      }
    }
  }
}
</script>