<template>
  <div class="box box--no-shadow filters">
    <h5 class="box__title">Filtros de búsqueda</h5>
    <div class="filters-options">
      <div class="form__group form__group--sm form__group--auto-width">
        <select class="form__input" v-model="sortOrder" @change="changeType()" placeholder="Filtrar por">
          <option value="default" disabled selected >Filtrar por</option>
          <option value="fecha">Fecha</option>
          <option value="causes">Causas</option>
          <option value="areas">Áreas de Interés</option>
        </select>
      </div>
      <div v-if="this.sortOrder == 'fecha'" class="form__group form__group--sm form__group--auto-width" >
        <select class="form__input" v-model="extra.mes" @change="this.clearFilter = true">
          <option value="0" selected disabled>Seleccionar mes</option>
          <option v-for="(month, index) in months" :key="month.id" :value="monthsNumber[index]" >{{month.charAt(0).toUpperCase() + month.slice(1)}}</option>
        </select>
        <select class="form__input" v-model="extra.ano" @change="this.clearFilter = true">
          <option value="0" selected disabled>Seleccionar año</option>
          <option v-for="year in years" :key="year.id" :value="year" >{{year}}</option>
        </select>
      </div>
      <div v-if="sortOrder == 'causes'" class="form__group form__group--sm form__group--auto-width" >
        <select class="form__input" name="" v-model="extra.causa" id="" @change="this.clearFilter = true">
          <option value="0" selected disabled>Seleccionar causa</option>
          <option v-for="cause in causes" :key="cause" :value="cause.id" >{{cause.name}}</option>
        </select>
      </div>
      <div v-if="sortOrder == 'areas'" class="form__group form__group--sm form__group--auto-width" >
        <select class="form__input" name="" v-model="extra.area" id="" @change="this.clearFilter = true">
          <option value="0" selected disabled>Seleccionar area</option>
          <option v-for="area in areas" :key="area" :value="area.id" >{{area.name}}</option>
        </select>
      </div>
      <button class="btn btn--primary btn--sm" v-if="this.sortOrder != 'default'" @click="sortEntries()">Buscar</button>
      <button v-if="this.clearFilter == true" class="btn btn--primary btn--outline btn--sm" @click="RemoveFilters()">Limpiar Filtros</button>
    </div>
  </div>
  <!--Listado-->
  <EventsList @cancelled="cancelMyEvent" @refresh="this.$emit('refresh')" @total="totalEventos" @filter="filtered = false" @fetchNewEvents="fetchEventsList" :IsFiltered="filtered" :NewEvents="EventList" :months="months" :years="years" :areas="areas" :causes="causes" :Total="total"></EventsList>
</template>

<script>
import EventsList from '@/components/EventsList.vue'
export default {
  name: 'EventsListFilters',
  components: { EventsList },
  emits: ["refresh-month-years" , "refresh", "cancelled"],
  props:{
    months: Array,
    monthsNumber: Array,
    years: Array,
    areas: Array,
    causes: Array
  },
  data() {
    return {
      loading: true,
      perPage: 15,
      page: 1,
      total: 0,
      sharing: {},
      sortOrder: 'default',
      sortOrderCopy: 'default',
      extra: {ano: 0, mes: 0, causa: 0 , area: 0},
      extraCopy: {ano: 0, mes: 0, causa: 0 , area: 0},
      EventList:[],
      clearFilter: false,
      filtered: false
    }
  },
  async mounted(){
    this.total = (await this.totalEvents(this.sortOrder , this.extra))
  },
  methods:{
    async totalEventos(){
      this.total = await this.totalEvents(this.sortOrderCopy , this.extraCopy)
    },
    async loadEvents(order , extra) {
      return (await this.$axios.get(`Event/${this.page}/${order}/${JSON.stringify(extra)}`)).data
    },
    async totalEvents(order, extra){
      return (await this.$axios.get(`Event/total/${order}/${JSON.stringify(extra)}`)).data
    },
    async fetchEventsList(ev){
      this.EventList = (await this.$axios.get(`Event/${ev.page}/${this.sortOrder}/${JSON.stringify(this.extra)}`)).data
    },
    cancelMyEvent(ev){
      this.$emit("cancelled", { eventid: ev.eventid })
    },
    changeType(){
      this.extra.ano = 0
      this.extra.mes = 0
      this.extra.causa = 0
      this.extra.area = 0
      this.extraCopy = JSON.parse(JSON.stringify(this.extra))
      this.clearFilter = false
    },
    async RemoveFilters(){
      this.EventList = []
      this.sortOrder = 'default'
      this.filtered = true
      this.changeType()
      await this.sortEntries()
    },
    async sortEntries() {
      //boton buscar del filtro
      if(this.sortOrder == 'fecha' && this.extra.ano == 0 && this.extra.mes == 0){
        return this.$toast.error('Debes seleccionar mes o año')
      }
      if (this.sortOrder == 'causes' && this.extra.causa == 0){
        return this.$toast.error('Debes seleccionar al menos una causa')
      }
      if(this.sortOrder == 'areas' && this.extra.area == 0){
        return this.$toast.error('Debes seleccionar al menos una área de interés')
      }

      this.filtered = true
      this.page = 1
      this.perPage = 15

      this.sortOrderCopy = this.sortOrder
      this.extraCopy = { ...this.extra }
      await this.refreshFilter2()

      this.total = await this.totalEvents(this.sortOrderCopy, this.extraCopy)
      this.EventList = await this.loadEvents(this.sortOrderCopy , this.extraCopy)
    },
    async refreshFilter2(){
      this.$emit("refresh-month-years", this.extra)
    }
  }

}
</script>