<template>
  <div :ref="'container'">
    <template v-if="loading">
      <Loader v-for="index in perPage" :key="index" viewBox="0 0 300 200">
        <rect x="0" y="0" rx="3" ry="3" width="250" height="30" />
        <rect x="20" y="40" rx="3" ry="3" width="260" height="15" />
        <rect x="20" y="70" rx="3" ry="3" width="260" height="15" />
        <rect x="20" y="100" rx="3" ry="3" width="170" height="15" />
      </Loader>
    </template>
    <div class="events" v-for="(event) in List" :key="event.id">
      <div class="section__titles section-month" >
        <h5 class="section__title text-alt">{{event.month}} {{event.year}}</h5>
      </div>
      <div class="events__list">
        <div v-for="event2 in event.events" :key="event2.id" :class="['event__card box box--no-shadow', event2.cancelled ? 'box--cancelled' : '']">
          <span class="tag tag--danger tag-cancelled" v-if="event2.cancelled">Evento cancelado</span>
          <div class="event-card__header">
            <div class="event-card__header-titles">
              <div class="user__image" v-if="event2.autor.profilePicture">
                <img referrerpolicy="no-referrer" :src="event2.autor.profilePicture">
              </div>
              <div v-else class="user__image">
                <img src="@/assets/images/avatar_default.png">
              </div>
              <div class="event-card__host-name">
                <h5>{{ event2.autor.fullName }}</h5>
                <p class="caption">Organizador</p>
              </div>
            </div>
            <div class="post__options" v-if="!event2.cancelled">
              <DropdownMenu>
                <template #button>
                  <button class="btn btn--icon btn--circle">
                    <img src="@/assets/images/icons/ico_dots--bl.svg">
                  </button>
                </template>
                <template #options>
                  <router-link v-if="$store.state.user.id == event2.autor.id" class="dropdown__option" :to="{ name: 'EditEvent', params: { id: event2.id } }" >
                    Editar
                  </router-link>
                  <a class="dropdown__option" href="#" @click.prevent="displayShareDialog(event2)">
                    Compartir evento
                  </a>
                  <a class="dropdown__option danger" v-if="$store.state.user.id == event2.autor.id && !event2.cancelled" @click="showPreview = true , idEventModal.id = event2.id , idEventModal.mode = 'cancel'">
                    Cancelar evento
                  </a>
                  <a class="dropdown__option danger" v-if="$store.state.user.id == event2.autor.id" href="#" @click="showPreview = true, idEventDelete = event2.id , idEventModal.id = event2.id , idEventModal.mode = 'delete', asAdmin = false">
                    Eliminar
                  </a>
                  <a class="dropdown__option danger" v-else-if="$store.state.user.role_id == 1" href="#" @click="showPreview = true, idEventDelete = event2.id , idEventModal.id = event2.id , idEventModal.mode = 'delete', asAdmin = true">
                    Eliminar
                  </a>
                </template>
              </DropdownMenu>
            </div>
          </div>
          <div class="event-card__content">
            <div class="event-card__title">
              <h4>{{ event2.title }}</h4>
              <p>{{ $moment(event2.fecha).format('DD/MM/YYYY') }} a las {{$moment(event2.hora).format('HH:mm')}} Hrs. - {{event2.onSite ? 'Presencial' : 'Digital'}}</p>
              <br>
              <p>{{ event2.description }}</p>
              <br>
            </div>
            <div class="post__cover-image" v-if="event2.imageUrl">
              <img referrerpolicy="no-referrer" :src="event2.imageUrl">
            </div>
            <div class="event-card__description">
              <p class="caption text-alt">{{ event2.participants }} participante(s)</p>
            </div>
            <div class="event__actions form__actions form__actions--xs">
              <router-link v-if="!event2.cancelled" class="btn btn--primary btn--link btn--link-no-padding" :to="{ name: 'Event', params: { id: event2.id } }">Más información</router-link>
              <a v-else class="btn btn--primary btn--link btn--link-no-padding" >Más información</a>
              <img class="icon" v-if="!event2.cancelled" src="@/assets/images/icons/ico_share--orange.svg" @click.prevent="displayShareDialog(event2)">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box box--no-shadow text-center" v-if="!List.length">
      <p>No se han encontrado eventos en esta sección</p>
    </div>
    <template v-if="loading">
      <Loader v-for="index in perPage" :key="index" viewBox="100 100 300 200">
        <rect x="0" y="0" rx="3" ry="3" width="250" height="30" />
        <rect x="20" y="40" rx="3" ry="3" width="260" height="15" />
        <rect x="20" y="70" rx="3" ry="3" width="260" height="15" />
        <rect x="20" y="100" rx="3" ry="3" width="170" height="15" />
      </Loader>
    </template>
  </div>
  <share-dialog v-if="showShareDialog" :sharing="sharing" @close="showShareDialog = false"></share-dialog>
  <modal-dialog v-if="showPreview" @close="showPreview = false">
    <template #title><h3 class="text-center">¿Estas seguro de {{idEventModal?.mode == 'cancel' ? 'cancelar' : 'eliminar'}} el evento?</h3></template>
    <template #footer>
      <div class="form__actions form__actions--center">
        <button class="btn btn--primary btn--outline" type="button" @click.prevent="showPreview = false" >Cancelar</button>
        <button class="btn btn--primary" v-if="this.idEventModal.mode == 'cancel'" type="button"  @click.prevent="cancelEvent()" :disabled="submitting">Confirmar</button>
        <button class="btn btn--primary" v-if="this.idEventModal.mode == 'delete'" type="button"  @click.prevent="deleteEvent()" :disabled="submitting">Confirmar</button>
      </div>
    </template>
  </modal-dialog>
</template>

<script>
import { ContentLoader as Loader } from 'vue-content-loader'
import DropdownMenu from '@/components/DropdownMenu.vue'
import ModalDialog from '@/components/ModalDialog.vue'
import ShareDialog from '@/components/ShareDialog.vue'

export default {
  name: 'EventsList',
  components: {DropdownMenu , Loader , ModalDialog , ShareDialog},
  props:{
    NewEvents: Array,
    months: Array,
    years: Array,
    areas: Object,
    causes: Object,
    Total: Number,
    IsFiltered: Boolean
  },
  emits: ["fetch-new-events", "total" , "filter", "refresh", "cancelled"],
  data() {
    return {
      loading: true,
      perPage: 15,
      page: 1,
      List: [],
      loadingMoreEvents: false,
      showShareDialog: false,
      showPreview: false,
      sharing: {},
      contador: 0,
      filtered: false,
      submitting: false,
      idEventModal: {id: 0 , mode: '', asAdmin: false}
    }
  },
  async mounted(){
    this.loadingMoreEvents = true
    this.$emit("fetch-new-events" , { page: this.page , perPage: this.perPage})
    window.addEventListener('scroll', this.onScroll);
  },
  beforeUnmount() {
    this.$nextTick(() => {
      window.removeEventListener('scroll', this.onScroll);
    })
  },
  methods:{
    getDateObjectFromDateString(dateString){
      const dateParts = (dateString || '').split('-').map(part => parseInt(part))
      if (dateParts.length) {
        dateParts[1]--
      }
      return new Date(...dateParts).toISOString()
    },
    counting(list){
      this.contador = 0
      list.map((item) => this.contador += item.events.length)
    },
    async onScroll() {
      if(this.contador < this.Total){
        this.$emit("total")
      }
      this.counting(this.List)
      if(this.$refs.container.getBoundingClientRect().bottom < window.innerHeight + 300 && !this.loadingMoreEvents && this.contador < this.Total){
        this.loadingMoreEvents = true
        this.$emit("fetch-new-events" , { page: this.page , perPage: this.perPage})
      }

    },
    async deleteEvent(){  
      this.submitting = true
      let res = null
      if(this.idEventModal.asAdmin)
        res = await this.$axios.post('Event/delete-event', {event_id: this.idEventModal.id})
      else
        res = await this.$axios.post('Event/delete-event', {event_id: this.idEventModal.id, as_admin: true})
      if(res.status == 200){
        this.$toast.success('El evento ha sido eliminado exitosamente')
        this.showPreview = false
        //this.$router.push('#events')
        //Refrescar listado
        this.page = 1
        this.perPage = 15
        this.List = []
        //this.$emit("fetch-new-events" , { page: this.page , perPage: this.perPage})
        this.total = this.$emit("total")
        //Fin refresco
        //Refresco Mis Eventos
        this.$emit("refresh")
        //Fin Refresco Mis Eventos
        this.submitting = false
      }
      else{
        this.$toast.error(res.data)
        this.submitting = false
      }
    },
    async cancelEvent(){
      this.submitting = true
      const res = await this.$axios.post('Event/cancel-event', {event_id: this.idEventModal.id})
      if(res.status == 200){
        this.$toast.success('El evento ha sido cancelado')
        this.showPreview = false
        this.List.forEach((item) => {
          item.events.map((e, index) => e.id == this.idEventModal.id ? item.events[index].cancelled = true : null)
        })

        //Cambiar estado a cancelado en Mis Eventos
        this.$emit("cancelled", { eventid: this.idEventModal.id})
        this.submitting = false
      }
      else
      {
        this.$toast.error(res.data)
        this.submitting = false
      }
    },
    displayShareDialog(el){
      this.showShareDialog = true
      this.sharing.url = `${window.location.origin}/evento/${el.id}`
      this.sharing.title = el.title || el.description
      //this.sharing.description = el.description
      //this.sharing.quote = 'quote'
      this.sharing.hashtags = el.tags.map(n => n.name)
      this.sharing.twitterUser = el.secondUrl
    },
    EventsListFilterData(){
      //Crea el listado para mostrar los eventos
      this.NewEvents.forEach((ele,index) => {
        this.List.filter((item) => {
          let mes = this.$moment(this.getDateObjectFromDateString(ele.fecha)).format('MMMM')
          let ano = this.$moment(this.getDateObjectFromDateString(ele.fecha)).format('yyyy')
          return mes == item.month && ano == item.year
          //return this.$moment(ele.fecha).format('MMMM') == item.month && this.$moment(ele.fecha).format('yyyy') == item.year
        }).forEach((item) => {
          item.events.push(ele)
          delete this.NewEvents[index]
        })
      })

      var eventsfilter = []
      this.years.forEach((year) => {
        this.months.forEach(mes => {
          eventsfilter.push({ year: year , month: mes , events: (this.NewEvents.filter((item) => {
            let month = this.$moment(this.getDateObjectFromDateString(item.fecha)).format('MMMM')
            let ano = this.$moment(this.getDateObjectFromDateString(item.fecha)).format('yyyy')
            if(month == mes && ano == year){
              return item
            }else{
              return null
            }
          }))})
          //eventsfilter.push({ year: year , month: mes , events: (this.NewEvents.filter((item) => this.$moment(item.fecha).format('MMMM') == mes && this.$moment(item.fecha).format('yyyy') == year ? item : null))})
        })
      })
      this.List.push(... eventsfilter.filter(function(item) {
        return item.events.length > 0 
      }))
    }
  },
  watch:{
    NewEvents() {
      if(this.IsFiltered){
        this.page = 1
        this.perPage = 15
        this.List.length = 0
        this.$emit("filter")
      }
      this.$emit("total")
      this.EventsListFilterData()
      this.counting(this.List)
      this.page = this.page + 1
      this.loading = false
      this.loadingMoreEvents = false
    },
  }

}
</script>