<template>
  <section class="section" :ref="'miseventos'">
    <div class="section__titles">
      <h3 class="section__title">Mis eventos</h3>
    </div>
    <div class="events events-aside-list" v-for="(event) in List" :key="event.id">
      <div :id="event.month" class="section__titles">
        <h5 class="section__title text-alt">{{ event.month }} {{ event.year }}</h5>
      </div>
      <div v-for="(event2) in event.events" :key="event2.id">
        <div :class="['event__card box box--no-shadow', event2.cancelled ? 'box--cancelled' : '']">
          <span class="tag tag--danger tag-cancelled" v-if="event2.cancelled">Evento cancelado</span>
          <div class="event-card__content">
            <div class="event-card__title">
              <h5>{{ event2.title }}</h5>
              <br>
              <p class="caption">{{ $moment(event2.fecha).format('DD/MM/YYYY') }} a las
                {{ $moment(event2.hora).format('HH:mm') }} Hrs.</p>
              <br>
              <p class="caption">{{ event2.description }}</p>
            </div>
            <div class="event__actions form__actions form__actions--xs">
              <router-link v-if="!event2.cancelled" class="btn btn--primary btn--link btn--link-no-padding"
                :to="{ name: 'Event', params: { id: event2.id } }">Más información</router-link>
              <a v-else class="btn btn--primary btn--link btn--link-no-padding" >Más información</a>
              <img v-if="!event2.cancelled" class="icon" src="@/assets/images/icons/ico_share--orange.svg"
                @click.prevent="displayShareDialog(event2)">
            </div>
          </div>
        </div>
        <br>
      </div>
    </div>
    <div class="box box--no-shadow text-center" v-if="!List.length">
      <p>Aún no tienes eventos</p>
    </div>
    <!--FIN MIS EVENTOS-->
  </section>
  <share-dialog v-if="showShareDialog" :sharing="sharing" @close="showShareDialog = false"></share-dialog>

</template>
<script>
import ShareDialog from '@/components/ShareDialog.vue'

export default {
  name: 'MyEventsList',
  components: { ShareDialog },
  props: {
    NewEvents: Array,
    months: Array,
    years: Array,
    areas: Object,
    causes: Object,
    Total: Number,
    IsRefresh: Boolean,
    EventIdCancel: Number
  },
  emits: ["fetch-new-events", "total", "refresh"],
  data() {
    return {
      loading: true,
      perPage: 15,
      page: 1,
      List: [],
      loadingMoreEvents: false,
      showShareDialog: false,
      sharing: {},
      contador: 0,
    }
  },
  async mounted() {
    this.loadingMoreEvents = true
    this.$emit("fetch-new-events", { page: this.page, perPage: this.perPage })
    window.addEventListener('scroll', this.onScroll);
  },
  beforeUnmount() {
    this.$nextTick(() => {
      window.removeEventListener('scroll', this.onScroll);
    })
  },
  methods: {
    counting(list) {
      this.contador = 0
      list.map((item) => this.contador += item.events.length)
    },
    async onScroll() {
      if (this.contador < this.Total) {
        this.$emit("total")
      }
      this.counting(this.List)
      if (this.$refs.miseventos.getBoundingClientRect().bottom < window.innerHeight + 300 && !this.loadingMoreEvents && this.contador < this.Total) {
        this.loadingMoreEvents = true
        this.$emit("fetch-new-events", { page: this.page, perPage: this.perPage })
      }
    },
    displayShareDialog(el) {
      this.showShareDialog = true
      this.sharing.url = `${window.location.origin}/evento/${el.id}`
      this.sharing.title = el.title || el.description
      //this.sharing.description = el.description
      //this.sharing.quote = 'quote'
      this.sharing.hashtags = el.tags.map(n => n.name).join(',')
      this.sharing.twitterUser = el.secondUrl
    },
    EventsListFilterData() {
      //Crea el listado para mostrar los eventos
      this.NewEvents.forEach((ele, index) => {
        this.List.filter((item) => {
          return this.$moment(ele.fecha).format('MMMM') == item.month && this.$moment(ele.fecha).format('yyyy') == item.year
        }).forEach((item) => {
          item.events.push(ele)
          delete this.NewEvents[index]
        })
      })

      var eventsfilter = []
      this.years.forEach((year) => {
        this.months.forEach(mes => {
          eventsfilter.push({ year: year, month: mes, events: (this.NewEvents.filter((item) => this.$moment(item.fecha).format('MMMM') == mes && this.$moment(item.fecha).format('yyyy') == year ? item : null)) })
        })
      })
      this.List.push(...eventsfilter.filter(function (item) {
        return item.events.length > 0
      }))
    }
  },
  watch: {
    NewEvents() {
      if (this.IsRefresh) {
        this.page = 1
        this.perPage = 15
        this.List.length = 0
        this.$emit("refresh")
      }
      this.EventsListFilterData()
      this.counting(this.List)
      this.page = this.page + 1
      this.loading = false
      this.loadingMoreEvents = false
    },
    EventIdCancel() {
      this.List.forEach((item) => {
        item.events.map((e, index) => e.id == this.EventIdCancel ? item.events[index].cancelled = true : null)
      })
    }
  }
}
</script>